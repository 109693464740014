import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export enum ICustomerTransactionTypeEnum {
  OAT = "oat",
  PAYMENT_LINK = "payment_link",
  PAT = "pat",
  DELIVERY = "delivery",
  PICKUP = "pickup",
}

export type ICurrency = {
  value: number;
  currency: string;
};

export enum CustomerTabFilterTypeEnum {
  RECENT = "recent",
  INACTIVE = "inactive",
  BEST = "best",
  CRITICAL = "critical",
}

interface IFetchCustomersRequest {
  businessId: string;
  page: number;
  tabFilter?: `${CustomerTabFilterTypeEnum}`;
  search?: string;
  sort?: string;
  sortDirection?: "DESC" | "ASC";
}

interface ICustomersParams {
  page: number;
  tab_filter?: `${CustomerTabFilterTypeEnum}`;
  phone_number?: string;
  sort?: string;
}

type IDataResponse = {
  phone_number: string;
  name: string;
  email: string;
  transactions_count: number;
  amount: ICurrency;
  last_transaction_at: string;
  last_transaction_type: `${ICustomerTransactionTypeEnum}`;
  average_rating: string | null;
};

export type ICustomer = {
  phoneNumber: string;
  name: string | null;
  email: string | null;
  transactionsCount: number;
  amount: ICurrency;
  lastTransactionAt: Date;
  lastTransactionType: `${ICustomerTransactionTypeEnum}`;
  averageRating: string | null;
};

export interface IFetchCustomersResponse {
  data: Array<ICustomer>;
  paginator: {
    total: number;
    perPage: number;
    currentPage: number;
    lastPage: number;
  };
}

export class FetchCustomersError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchCustomers");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchCustomers(
  request: IFetchCustomersRequest
): Promise<IFetchCustomersResponse> {
  let response: AxiosResponse;

  let params: ICustomersParams = {
    page: request.page,
    tab_filter: request.tabFilter,
  };

  if (request.search) {
    params = {
      ...params,
      phone_number: request.search,
    };
  }

  if (request.sort) {
    const mapKeys: Record<string, string> = {
      transactionsCount: "count",
      amount: "total_amount",
      lastTransactionAt: "last_transaction_at",
      averageRating: "average_rating",
    };

    let sortValue = mapKeys[request.sort];

    if (request.sortDirection === "DESC") {
      sortValue = `-${sortValue}`;
    }

    params = {
      ...params,
      sort: sortValue,
    };
  }

  try {
    response = await http.get(
      `/crm/contacts-by-business/${request.businessId}`,
      {
        params,
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchCustomersError(error);
  }

  return {
    data: response.data?.data.map((obj: IDataResponse) => ({
      phoneNumber: obj.phone_number,
      name: obj.name,
      email: obj.email,
      transactionsCount: obj.transactions_count,
      amount: obj.amount,
      lastTransactionAt: new Date(obj.last_transaction_at),
      lastTransactionType: obj.last_transaction_type,
      averageRating: obj.average_rating,
    })),
    paginator: {
      total: response.data?.meta.total,
      perPage: response.data?.meta.per_page,
      currentPage: response.data?.meta.current_page,
      lastPage: response.data?.meta.last_page,
    },
  };
}
