











































































import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
} from "@vue/composition-api";
import { bemBuilder } from "@chatfood/core-utils";
import {
  AtomText,
  AtomDate,
  AtomMoney,
  MolRating,
  MolPagination,
  OrgTable,
  IOrgTableProps,
  ISortDirection,
} from "@/v2/new-design-system";
import {
  fetchTransactions,
  IFetchTransactionsRequest,
  IFetchTransactionsResponse,
} from "@/v2/module/customer-management/repo/fetch-transactions";
import { report } from "@chatfood/bug-reporter";
import { ICustomerManagementRouteEnum } from "@/v2/module/customer-management/customer-mangement-route.enum";
import { ITransactionType } from "@/v2/module/customer-management/domain/transaction-type";
import {
  deliveryPartnerName,
  IDeliveryPartner,
} from "@/v2/module/customer-management/domain/delivery-partner";
import { ITransactionStatus } from "@/v2/module/customer-management/domain/transaction-status";
import { match, P } from "ts-pattern";
import {
  fromRepository,
  ITransaction,
} from "@/v2/module/customer-management/transactions/transaction.type";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { ITransactionTypeRoute } from "@/v2/module/customer-management/transactions/transaction-type-route.type";

const css = bemBuilder("customer-management-transactions");

export default defineComponent({
  name: "CustomerManagementTransactions",
  components: {
    AtomText,
    AtomDate,
    AtomMoney,
    MolRating,
    MolPagination,
    OrgTable,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
    tabName: {
      type: String as PropType<ITransactionTypeRoute>,
      required: true,
    },
  },
  setup(props) {
    const isListLoading = ref(false);
    const transactionList = ref<ITransaction[]>([]);
    const pagination = ref<IFetchTransactionsResponse["paginator"]>();

    const hasItems = computed(() => Boolean(pagination.value?.total));

    const transactionType = computed(() => {
      const filterMap = new Map<ITransactionTypeRoute, ITransactionType>([
        [ICustomerManagementRouteEnum.DINE_IN_TRANSACTIONS, "dineIn"],
        [ICustomerManagementRouteEnum.DELIVERY_TRANSACTIONS, "delivery"],
        [ICustomerManagementRouteEnum.PICKUP_TRANSACTIONS, "pickup"],
        [ICustomerManagementRouteEnum.PAYMENT_LINK_TRANSACTIONS, "paymentLink"],
      ]);

      return filterMap.get(props.tabName) || "dineIn";
    });

    const sortBy = ref("createdAt");
    const sortColumnDirection = ref<ISortDirection>("DESC");

    const columnsPerType = computed(() => {
      const columnsMap: Record<ITransactionType, string[]> = {
        dineIn: [
          "uid",
          "createdAt",
          "outlet",
          "paymentMethod",
          "amount",
          "tipAmount",
          "rating",
        ],
        delivery: [
          "uid",
          "createdAt",
          "outlet",
          "paymentMethod",
          "deliveryPartner",
          "status",
          "amount",
          "rating",
        ],
        pickup: [
          "uid",
          "createdAt",
          "outlet",
          "paymentMethod",
          "status",
          "amount",
          "rating",
        ],
        paymentLink: [
          "uid",
          "createdAt",
          "outlet",
          "paymentDescription",
          "amount",
        ],
        // Pay at Table is not implemented
        pat: [],
      };

      return columnsMap[transactionType.value];
    });

    const tableColumns: IOrgTableProps["columns"] = {
      uid: {
        header: () => t("module.customer_management.transactions.list_uid"),
        show: columnsPerType.value.includes("uid"),
        sortable: false,
        sortDirection: "NONE",
      },
      createdAt: {
        header: () => t("module.customer_management.transactions.list_date"),
        show: columnsPerType.value.includes("createdAt"),
        sortable: true,
        sortDirection: "DESC",
      },
      outlet: {
        header: () => t("module.customer_management.transactions.list_outlet"),
        show: columnsPerType.value.includes("outlet"),
        sortable: true,
        sortDirection: "NONE",
      },
      paymentMethod: {
        header: () => t("module.customer_management.transactions.list_payment"),
        show: columnsPerType.value.includes("paymentMethod"),
        sortable: true,
        sortDirection: "NONE",
      },
      paymentDescription: {
        header: () =>
          t("module.customer_management.transactions.list_purpose_payment"),
        show: columnsPerType.value.includes("paymentDescription"),
        sortable: true,
        sortDirection: "NONE",
      },
      deliveryPartner: {
        header: () =>
          t("module.customer_management.transactions.list_delivery"),
        show: columnsPerType.value.includes("deliveryPartner"),
        sortable: true,
        sortDirection: "NONE",
      },
      amount: {
        header: () => t("module.customer_management.transactions.list_sum"),
        show: columnsPerType.value.includes("amount"),
        sortable: true,
        sortDirection: "NONE",
      },
      tipAmount: {
        header: () => t("module.customer_management.transactions.list_tips"),
        show: columnsPerType.value.includes("tipAmount"),
        sortable: true,
        sortDirection: "NONE",
      },
      status: {
        header: () => t("module.customer_management.transactions.list_status"),
        show: columnsPerType.value.includes("status"),
        sortable: true,
        sortDirection: "NONE",
      },
      rating: {
        header: () => t("module.customer_management.transactions.list_rating"),
        show: columnsPerType.value.includes("rating"),
        sortable: true,
        sortDirection: "NONE",
      },
    };

    const onSort = (
      key: IFetchTransactionsRequest["sort"]["field"],
      sortDirection: ISortDirection
    ) => {
      if (
        (!key && !sortDirection) ||
        (key === sortBy.value && sortColumnDirection.value === sortDirection)
      ) {
        return;
      }

      sortBy.value = key;
      sortColumnDirection.value = sortDirection;

      getTransactionList(pagination.value?.currentPage, key, sortDirection);
    };

    const getTransactionList = async (
      page = 1,
      sort?: IFetchTransactionsRequest["sort"]["field"],
      sortDirection?: ISortDirection
    ) => {
      isListLoading.value = true;

      try {
        const transactions = await fetchTransactions({
          businessId: props.businessId,
          contactId: props.customerId,
          type: transactionType.value,
          page: page,
          sort: {
            field: sort ?? "createdAt",
            direction: sortDirection ?? "DESC",
          },
        });

        transactionList.value = transactions.data.map(fromRepository);
        pagination.value = transactions.paginator;
      } catch (e) {
        generalErrorToast();
        report(e);
      } finally {
        isListLoading.value = false;
      }
    };

    const deliveryLabel = (deliveryPartner?: IDeliveryPartner): string =>
      (deliveryPartner && deliveryPartnerName[deliveryPartner]) ||
      t("module.customer_management.transactions.own_delivery");

    const transactionStatusLabel = (status: ITransactionStatus): string => {
      return match<[ITransactionType, ITransactionStatus], string>([
        transactionType.value,
        status,
      ])
        .with(["delivery", "complete"], () =>
          t("module.customer_management.transaction_status.delivery_complete")
        )
        .with(["pickup", "complete"], () =>
          t("module.customer_management.transaction_status.pickup_complete")
        )
        .with([P._, "complete"], () =>
          t("module.customer_management.transaction_status.complete")
        )
        .with([P._, "cancelled"], () =>
          t("module.customer_management.transaction_status.cancelled")
        )
        .exhaustive();
    };

    onMounted(async () => {
      await getTransactionList();
    });

    return {
      t,
      css,
      isListLoading,
      tableColumns,
      transactionList,
      pagination,
      hasItems,
      getTransactionList,
      onSort,
      deliveryLabel,
      transactionStatusLabel,
    };
  },
});
