import { http } from "@/v2/repo/http";
import {
  IFetchTransactionsCountContract,
  IFetchTransactionsCountRequest,
  IFetchTransactionsCountResponse,
} from "@/v2/module/customer-management/repo/fetch-transactions-count/types";

export class FetchTransactionsCountError extends Error {}

export const fetchTransactionsCount = async (
  request: IFetchTransactionsCountRequest
): Promise<IFetchTransactionsCountResponse> => {
  try {
    const { data } = await http.get<IFetchTransactionsCountContract>(
      `/crm/businesses/${request.businessId}/contacts/${request.contactId}/transactions-count`
    );

    return {
      dineIn: data.OAT ?? 0,
      delivery: data.DELIVERY ?? 0,
      pickup: data.PICKUP ?? 0,
      paymentLink: data.PAYMENT_LINK ?? 0,
      pat: data.PAT ?? 0,
    };
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTransactionsCountError(e);
  }
};
